<template>
  <div>
    <span class=" logo text-grey-10">Só<span class=" logo text-primary">Frases</span></span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Logo',
  setup () {}
})
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Special+Elite&display=swap");

.logo {
  font-family: "Special Elite", cursive !important;
}
</style>
